export const actionTypes = {
  // user
  LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  LOGIN_FAIL: 'USER_LOGIN_FAIL',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAIL: 'LOGOUT_FAIL',

  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAIL: 'GET_USER_FAIL',

  EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  EDIT_USER_FAIL: 'EDIT_USER_FAIL',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAIL: 'CHANGE_PASSWORD_FAIL',

  GET_DASHBOARD_REQUEST: 'GET_DASHBOARD_REQUEST',
  GET_DASHBOARD_SUCCESS: 'GET_DASHBOARD_SUCCESS',
  GET_DASHBOARD_FAIL: 'GET_DASHBOARD_FAIL',

  GET_PAYMENT_REQUEST: 'GET_PAYMENT_REQUEST',
  GET_PAYMENT_SUCCESS: 'GET_PAYMENT_SUCCESS',
  GET_PAYMENT_FAIL: 'GET_PAYMENT_FAIL',

  GET_TOTALPAY_REQUEST: 'GET_TOTALPAY_REQUEST',
  GET_TOTALPAY_SUCCESS: 'GET_TOTALPAY_SUCCESS',
  GET_TOTALPAY_FAIL: 'GET_TOTALPAY_FAIL',

  FORGET_PASSWORD_REQUEST: 'FORGET_PASSWORD_REQUEST',
  FORGET_PASSWORD_SUCCESS: 'FORGET_PASSWORD_SUCCESS',
  FORGET_PASSWORD_FAIL: 'FORGET_PASSWORD_FAIL',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAIL: 'RESET_PASSWORD_FAIL',

  RESET_KEY_REQUEST: 'RESET_KEY_REQUEST',
  RESET_KEY_SUCCESS: 'RESET_KEY_SUCCESS',
  RESET_KEY_FAIL: 'RESET_KEY_FAIL',

  RESET_KEY_CONFIRM_REQUEST: 'RESET_KEY_CONFIRM_REQUEST',
  RESET_KEY_CONFIRM_SUCCESS: 'RESET_KEY_CONFIRM_SUCCESS',
  RESET_KEY_CONFIRM_FAIL: 'RESET_KEY_CONFIRM_FAIL',

  GET_DASHBOARD_SERVICE_MONTH_REQUEST: 'GET_DASHBOARD_SERVICE_MONTH_REQUEST',
  GET_DASHBOARD_SERVICE_MONTH_SUCCESS: 'GET_DASHBOARD_SERVICE_MONTH_SUCCESS',
  GET_DASHBOARD_SERVICE_MONTH_FAIL: 'GET_DASHBOARD_SERVICE_MONTH_FAIL',

  GET_DASHBOARD_SERVICE_YEAR_REQUEST: 'GET_DASHBOARD_SERVICE_YEAR_REQUEST',
  GET_DASHBOARD_SERVICE_YEAR_SUCCESS: 'GET_DASHBOARD_SERVICE_YEAR_SUCCESS',
  GET_DASHBOARD_SERVICE_YEAR_FAIL: 'GET_DASHBOARD_SERVICE_YEAR_FAIL',

  GET_DASHBOARD_SERVICE_COUNT_REQUEST: 'GET_DASHBOARD_SERVICE_COUNT_REQUEST',
  GET_DASHBOARD_SERVICE_COUNT_SUCCESS: 'GET_DASHBOARD_SERVICE_COUNT_SUCCESS',
  GET_DASHBOARD_SERVICE_COUNT_FAIL: 'GET_DASHBOARD_SERVICE_COUNT_FAIL',

  GET_DASHBOARD_SERVICE_LIST_REQUEST: 'GET_DASHBOARD_SERVICE_LIST_REQUEST',
  GET_DASHBOARD_SERVICE_LIST_SUCCESS: 'GET_DASHBOARD_SERVICE_LIST_SUCCESS',
  GET_DASHBOARD_SERVICE_LIST_FAIL: 'GET_DASHBOARD_SERVICE_LIST_FAIL',

  EXPORT_SERVICE_LIST_REQUEST: 'EXPORT_SERVICE_LIST_REQUEST',
  EXPORT_SERVICE_LIST_SUCCESS: 'EXPORT_SERVICE_LIST_SUCCESS',
  EXPORT_SERVICE_LIST_FAIL: 'EXPORT_SERVICE_LIST_FAIL',

  EXPORT_DASHBOARD_SERVICE_MONTH_REQUEST: 'EXPORT_DASHBOARD_SERVICE_MONTH_REQUEST',
  EXPORT_DASHBOARD_SERVICE_MONTH_SUCCESS: 'EXPORT_DASHBOARD_SERVICE_MONTH_SUCCESS',
  EXPORT_DASHBOARD_SERVICE_MONTH_FAIL: 'EXPORT_DASHBOARD_SERVICE_MONTH_FAIL',

  EXPORT_DASHBOARD_SERVICE_YEAR_REQUEST: 'EXPORT_DASHBOARD_SERVICE_YEAR_REQUEST',
  EXPORT_DASHBOARD_SERVICE_YEAR_SUCCESS: 'EXPORT_DASHBOARD_SERVICE_YEAR_SUCCESS',
  EXPORT_DASHBOARD_SERVICE_YEAR_FAIL: 'EXPORT_DASHBOARD_SERVICE_YEAR_FAIL',

  // admin
  GET_ALL_USER_REQUEST: 'GET_ALL_USER_REQUEST',
  GET_ALL_USER_SUCCESS: 'GET_ALL_USER_SUCCESS',
  GET_ALL_USER_FAIL: 'GET_ALL_USER_FAIL',

  GET_NAME_SERVICE_REQUEST: 'GET_NAME_SERVICE_REQUEST',
  GET_NAME_SERVICE_SUCCESS: 'GET_NAME_SERVICE_SUCCESS',
  GET_NAME_SERVICE_FAIL: 'GET_NAME_SERVICE_FAIL',

  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAIL: 'CREATE_USER_FAIL',

  ADMIN_EDIT_USER_REQUEST: 'ADMIN_EDIT_USER_REQUEST',
  ADMIN_EDIT_USER_SUCCESS: 'ADMIN_EDIT_USER_SUCCESS',
  ADMIN_EDIT_USER_FAIL: 'ADMIN_EDIT_USER_FAIL',

  ACTIVATE_USER_REQUEST: 'ACTIVATE_USER_REQUEST',
  ACTIVATE_USER_SUCCESS: 'ACTIVATE_USER_SUCCESS',
  ACTIVATE_USER_FAIL: 'ACTIVATE_USER_FAIL',

  DEACTIVATE_USER_REQUEST: 'DEACTIVATE_USER_REQUEST',
  DEACTIVATE_USER_SUCCESS: 'DEACTIVATE_USER_SUCCESS',
  DEACTIVATE_USER_FAIL: 'DEACTIVATE_USER_FAIL',

  GET_WHITELIST_IPS_REQUEST: 'GET_WHITELIST_IPS_REQUEST',
  GET_WHITELIST_IPS_SUCCESS: 'GET_WHITELIST_IPS_SUCCESS',
  GET_WHITELIST_IPS_FAIL: 'GET_WHITELIST_IPS_FAIL',

  ADD_IP_REQUEST: 'ADD_IP_REQUEST',
  ADD_IP_SUCCESS: 'ADD_IP_SUCCESS',
  ADD_IP_FAIL: 'ADD_IP_FAIL',

  UPDATE_IP_REQUEST: 'UPDATE_IP_REQUEST',
  UPDATE_IP_SUCCESS: 'UPDATE_IP_SUCCESS',
  UPDATE_IP_FAIL: 'UPDATE_IP_FAIL',

  DELETE_IP_REQUEST: 'DELETE_IP_REQUEST',
  DELETE_IP_SUCCESS: 'DELETE_IP_SUCCESS',
  DELETE_IP_FAIL: 'DELETE_IP_FAIL',

  // common

  SET_BREADCRUMB: 'SET_BREADCRUMB',
};
