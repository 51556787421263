import actionTypes from '../action_types';
import { combineReducers } from 'redux';
import { toMap } from '../../../utils/toMap';

function login(state = {}, action) {
  switch (action.type) {
    case actionTypes.LOGIN_REQUEST:
      return {};
    case actionTypes.LOGIN_SUCCESS:
      return action.data;
    case actionTypes.LOGIN_FAIL:
      return action.data;
    default:
      return state;
  }
}

function logout(state = { status: 'fail' }, action) {
  switch (action.type) {
    case actionTypes.LOGOUT_REQUEST:
      return { status: 'loading' };
    case actionTypes.LOGOUT_SUCCESS:
      return { status: 'success' };
    case actionTypes.LOGOUT_FAIL:
      return { status: 'fail' };
    default:
      return state;
  }
}

function user(state = { user: {}, users: {} }, action) {
  switch (action.type) {
    case actionTypes.GET_ALL_USER:
      return { ...state, users: toMap(action.data) };
    case actionTypes.GET_USER:
      return { ...state, user: action.data };
    default:
      return state;
  }
}

function actions(state = { isSaved: false, isSuccess: false }, action) {
  switch (action.type) {
    case actionTypes.CREATE_USER_REQUEST:
      return { isSaved: false, isSuccess: false };
    case actionTypes.CREATE_USER_SUCCESS:
      return { isSaved: true, isSuccess: true };
    case actionTypes.CREATE_USER_FAIL:
      return { isSaved: true, isSuccess: false };

    case actionTypes.EDIT_USER_REQUEST:
      return { isSaved: false, isSuccess: false };
    case actionTypes.EDIT_USER_SUCCESS:
      return { isSaved: true, isSuccess: true };
    case actionTypes.EDIT_USER_FAIL:
      return { isSaved: true, isSuccess: false };

    case actionTypes.DELETE_USER_REQUEST:
      return { isSaved: false, isSuccess: false };
    case actionTypes.DELETE_USER_SUCCESS:
      return { isSaved: true, isSuccess: true };
    case actionTypes.DELETE_USER_FAIL:
      return { isSaved: true, isSuccess: false, message: action.data };

    case actionTypes.ACTIVATE_USER_REQUEST:
      return { isSaved: false, isSuccess: false };
    case actionTypes.ACTIVATE_USER_SUCCESS:
      return { isSaved: true, isSuccess: true };
    case actionTypes.ACTIVATE_USER_FAIL:
      return { isSaved: true, isSuccess: false };

    case actionTypes.CHANGE_ROLE_REQUEST:
      return { isSaved: false, isSuccess: false };
    case actionTypes.CHANGE_ROLE_SUCCESS:
      return { isSaved: true, isSuccess: true };
    case actionTypes.CHANGE_ROLE_FAIL:
      return { isSaved: true, isSuccess: false };

    case actionTypes.FORGET_PASSWORD_REQUEST:
      return { isSaved: false, isSuccess: false };
    case actionTypes.FORGET_PASSWORD_SUCCESS:
      return { isSaved: true, isSuccess: true };
    case actionTypes.FORGET_PASSWORD_FAIL:
      return { isSaved: true, isSuccess: false };

    case actionTypes.RESET_PASSWORD_REQUEST:
      return { isSaved: false, isSuccess: false };
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return { isSaved: true, isSuccess: true };
    case actionTypes.RESET_PASSWORD_FAIL:
      return { isSaved: true, isSuccess: false };

    case actionTypes.CHANGE_PASSWORD_REQUEST:
      return { isSaved: false, isSuccess: false };
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return { isSaved: true, isSuccess: true };
    case actionTypes.CHANGE_PASSWORD_FAIL:
      return { isSaved: true, isSuccess: false };
    default:
      return { ...state };
  }
}

export default combineReducers({
  login,
  logout,
  user,
  actions,
});
