import { combineReducers } from 'redux';
import { actionTypes } from './cvs/actions/actionTypes';
import App from '@mins/redux/app/reducer';
import Auth from '@mins/redux/auth/reducer';
import ThemeSwitcher from '@mins/redux/themeSwitcher/reducer';
import Ecommerce from '@mins/redux/ecommerce/reducer';
import LanguageSwitcher from '@mins/redux/languageSwitcher/reducer';

import Cias from './cias/reducers';
import Cvs from './cvs/reducers';

const appReducer = combineReducers({
    Auth,
    App,
    ThemeSwitcher,
    Ecommerce,
    LanguageSwitcher,
    Cias,
    Cvs,
});

const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === actionTypes.LOGOUT_SUCCESS) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;

// export default combineReducers({
//   Auth,
//   App,
//   ThemeSwitcher,
//   Ecommerce,
//   LanguageSwitcher,
//   Cias,
//   Cvs,
// });
