import { actionTypes } from '../actions/actionTypes';
import { combineReducers } from 'redux';

const breadcrumb = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_BREADCRUMB:
      return action.data;
    default:
      return state;
  }
};

export default combineReducers({
  breadcrumb,
});
