import { actionTypes } from '../actions/actionTypes';
import { combineReducers } from 'redux';

const getAllUser = (state = { users: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case actionTypes.GET_ALL_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.users,
      };
    case actionTypes.GET_ALL_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users.map((item) => (item.id === action.userId ? { ...item, is_active: true } : item))],
      };
    case actionTypes.DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users.map((item) => (item.id === action.userId ? { ...item, is_active: false } : item))],
      };
    case actionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.user],
      };
    case actionTypes.ADMIN_EDIT_USER_SUCCESS: {
      return {
        ...state,
        users: [...state.users.map((item) => (item.id === action.userId ? action.user : item))],
      };
    }
    default:
      return state;
  }
};

const getNameService = (state = { services: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_NAME_SERVICE_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case actionTypes.GET_NAME_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        services: action.data,
      };
    case actionTypes.GET_NAME_SERVICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

const activateUser = (state = { data: {} }, action) => {
  switch (action.type) {
    case actionTypes.ACTIVATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case actionTypes.ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.data,
      };
    case actionTypes.ACTIVATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

const deactivateUser = (state = { data: {} }, action) => {
  switch (action.type) {
    case actionTypes.DEACTIVATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case actionTypes.DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case actionTypes.DEACTIVATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

const createUser = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_USER_REQUEST:
      return {
        loading: true,
      };
    case actionTypes.CREATE_USER_SUCCESS:
      return {
        success: true,
      };
    case actionTypes.CREATE_USER_FAIL:
      return {
        error: action.error,
      };
    default:
      return state;
  }
};

const adminEditUser = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADMIN_EDIT_USER_REQUEST:
      return {
        loading: true,
      };
    case actionTypes.ADMIN_EDIT_USER_SUCCESS:
      return {
        success: true,
      };
    case actionTypes.ADMIN_EDIT_USER_FAIL:
      return {
        error: action.error,
      };
    default:
      return state;
  }
};

const getWhitelistIPs = (state = { list: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_WHITELIST_IPS_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case actionTypes.GET_WHITELIST_IPS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data,
      };
    case actionTypes.GET_WHITELIST_IPS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.ADD_IP_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.data],
      };
    case actionTypes.DELETE_IP_SUCCESS: {
      return {
        ...state,
        list: state.list.filter((item) => item.id !== action.ipId),
      };
    }
    default:
      return state;
  }
};

const addIP = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_IP_REQUEST:
      return {
        loading: true,
      };
    case actionTypes.ADD_IP_SUCCESS:
      return {
        success: true,
      };
    case actionTypes.ADD_IP_FAIL:
      return {
        error: action.error,
      };
    default:
      return state;
  }
};

const deleteIP = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_IP_REQUEST:
      return {
        loading: true,
      };
    case actionTypes.DELETE_IP_SUCCESS:
      return {
        success: true,
      };
    case actionTypes.DELETE_IP_FAIL:
      return {
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  getAllUser,
  getNameService,
  activateUser,
  deactivateUser,

  createUser,
  adminEditUser,

  getWhitelistIPs,
  addIP,
  deleteIP,
});
