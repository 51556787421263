import { actionTypes } from '../actions/actionTypes'
import { combineReducers } from 'redux'

export const changePassword = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_PASSWORD_REQUEST:
            return {
                loading: true,
            }
        case actionTypes.CHANGE_PASSWORD_SUCCESS:
            return {
                success: true,
            }
        case actionTypes.CHANGE_PASSWORD_FAIL:
            return {
                error: action.error,
            };
        default:
            return state
    }
}

export const resetPassword = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.RESET_PASSWORD_REQUEST:
            return {
                loading: true,
            }
        case actionTypes.RESET_PASSWORD_SUCCESS:
            return {
                isSuccess: true
            }
        case actionTypes.RESET_PASSWORD_FAIL:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export const forgetPassword = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.FORGET_PASSWORD_REQUEST:
            return {
                loading: true
            }
        case actionTypes.FORGET_PASSWORD_SUCCESS:
            return {
                isSuccess: true
            }
        case actionTypes.FORGET_PASSWORD_FAIL:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export default combineReducers({
    changePassword,
    resetPassword,
    forgetPassword,
})